import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Content from "./Content";
import Breadcrumbs from "../Breadcrumbs";
import GetInTouch from "../GetInTouch";
import BackToTop from "../BackToTop";
import BreadcrumbsData from "../breadcrumbs.json";
import { Helmet } from "react-helmet";

const RFP = () => {
  return (
    <>
      <Helmet>
        <title>Pályázat - S-Print Logistic</title>
      </Helmet>
      <Header />
      <Breadcrumbs
        title={BreadcrumbsData.rfp.title}
        description={BreadcrumbsData.rfp.description}
      />
      <Content />
      <GetInTouch />
      <Footer />
      <BackToTop />
    </>
  );
};
export default RFP;
