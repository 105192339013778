import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeOne from "./home";
import NotFound from "./notfound";
import About from "./about";
import Contact from "./contactus";
import Shipping from "./services/shipping";
import Transport from "./services/transport";
import WarehouseLogistics from "./services/warehouseLogistics";
import TireTrade from "./services/tireTrade";
import DepotService from "./services/depotService";
import Tac from "./tac/index";
import RFP from "./rfp";

function MainNavigation() {
  return (
    <Router>
      <Switch>
        {/* <Route path="/home-one" exact={true} component={HomeOne} /> */}

        {/** Pages */}
        <Route path="/" exact={true} component={HomeOne} />
        <Route path="/palyazat" exact={true} component={RFP} />
        {/* <Route path="/rolunk" exact={true} component={About} /> */}
        <Route path="/kapcsolat" exact={true} component={Contact} />
        <Route path="/aszf" exact={true} component={Tac} />
        <Route path="/notfound" exact={true} component={NotFound} />
        {/* Services */}
        <Route path="/szallitmanyozas" exact={true} component={Shipping} />
        <Route path="/fuvarozas" exact={true} component={Transport} />
        <Route
          path="/raktarlogisztika"
          exact={true}
          component={WarehouseLogistics}
        />
        <Route
          path="/gumiabroncsKereskedelem"
          exact={true}
          component={TireTrade}
        />
        <Route
          path="/deposzolgaltatas"
          exact={true}
          component={DepotService}
        />

        {/** Pages */}
        {/* <Route path="/pricing" exact={true} component={Pricing} />
        <Route path="/requestquote" exact={true} component={RequestQuote} />
        <Route path="/shortcodes" exact={true} component={ShortCodes} />
        <Route path="/typography" exact={true} component={Typography} /> */}
        {/** About Services Contact */}
        {/* <Route path="/about" exact={true} component={About} />
        <Route path="/services" exact={true} component={Services} />
        <Route path="/servicedetails" exact={true} component={ServiceDetails} />
        <Route path="/contact" exact={true} component={Contact} /> */}
        {/* <Route
          path="/contactusoption"
          exact={true}
          component={ContactusOption}
        /> */}

        {/** invalid routes redirection */}
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
}

export default MainNavigation;
