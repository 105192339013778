import React from "react";

const GetInTouch = () => {
  return (
    <>
      <section className="wide-tb-40 bg-navy-blue callout-style-1 ">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-4 col-md-12 mb-0">
              <h4 className="h4-xl">
                Ön is szeretné, ha szállítmánya a legjobb kezekben lenne?
              </h4>
            </div>
            <div className="col">
              <div className="center-text"></div>
            </div>
            <div className="col-sm-auto">
              <a href="kapcsolat" className="btn-theme bg-white light">
                Lépjen velünk kapcsolatba!
                <i className="icofont-rounded-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default GetInTouch;
