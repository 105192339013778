import React from "react";

const Content = () => {
  return (
    <>
      <main id="body-content">
        <section className="bg-white wide-tb-100">
          <div className="container pos-rel">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <p className="text-justify">
                  Forgóeszköz finanszírozáshoz kapcsolódó tájékoztató
                </p>
                <ul>
                  <li>
                    az Adós neve – <b>S-Print Logistic Bt.</b>
                  </li>
                  <li>
                    a Forgóeszköz finanszírozás tartalmának rövid bemutatása -{" "}
                    <b>Munkabér és járulékainak finanszírozása</b>
                  </li>
                  <li>
                    a Hitelprogram azonosító jele – <b>GINOP-8.3.5-18/B</b>
                  </li>
                  <li>
                    Hitel összege – <b>25 MILLIÓ FORINT</b>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <hr />

          <div className="container pos-rel">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <p className="text-justify">
                  Forgóeszköz finanszírozáshoz kapcsolódó tájékoztató
                </p>
                <ul>
                  <li>
                    az Adós neve – <b>S-Print Logistic Bt.</b>
                  </li>
                  <li>
                    a Forgóeszköz finanszírozás tartalmának rövid bemutatása -{" "}
                    <b>Munkabér és járulékainak finanszírozása</b>
                  </li>
                  <li>
                    a Hitelprogram azonosító jele – <b>GINOP-8.3.5-18/B</b>
                  </li>
                  <li>
                    Hitel összege – <b>25 MILLIÓ FORINT</b>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section className="map-bg">
          <div id="map-holder" className="pos-rel">
            <iframe
              title="google-map"
              width="100%"
              height="400px"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=4400%20Ny%C3%ADregyh%C3%A1za,%20T%C3%BCnde%20utca%2010/A&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
        </section>
      </main>
    </>
  );
};

export default Content;
