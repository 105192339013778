import React from "react";
import Header from "../../Header";
import Footer from "../../Footer";
import Content from "./Content";
import Breadcrumbs from "../../Breadcrumbs";
import BackToTop from "../../BackToTop";
import BreadcrumbsData from "../../breadcrumbs.json";
import GetInTouch from "../../GetInTouch";
import { Helmet } from "react-helmet";

const DepotService = () => {
  return (
    <>
      <Helmet>
        <title>Depószolgáltatás - S-Print Logistic</title>
      </Helmet>
      <Header />
      <Breadcrumbs
        title={BreadcrumbsData.services.depotService.title}
        description={
          BreadcrumbsData.services.depotService.description
        }
      />
      <Content />
      <GetInTouch />
      <Footer />
      <BackToTop />
    </>
  );
};
export default DepotService;
