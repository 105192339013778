import React from "react";

const Footer = () => {
  return (
    <>
      {/* footer start */}
      <footer className="wide-tb-30 bg-light-gray pb-0">
        <div className="container">
          <div className="row">
            {/* <div className="col-lg-6 col-md-12">
              <div className="logo-footer">
                <img src="images/s-print_logo1.png" alt="" />
              </div>
              {/* <p>
                Proin gravida nibh vel velit auctor aliquet. Aenean
                sollicitudin, lorem quis bibendum auctor, nisi elit consequat
                ipsum, nec sagittis sem nibh id elit.
              </p>
              <p>
                Vivamus ac ultrices diam, vitae accumsan tellus. Integer
                sollicitudin vulputate lacus, congue .
              </p> 
            </div> */}

            <div className="col-lg-6 col-md-12">
              <h3 className="footer-heading">Elérhetőségeink</h3>
              <p>
                <i className="icofont-phone"></i>{" "}
                Iroda:&nbsp;
                <a href="tel:+36309566529" className="ml-1">+36 30 956 6529</a>
              </p>
              <p>
                <i className="icofont-phone"></i>{" "}
                Futárszervezés:&nbsp;
                <a href="tel:+36309566528" className="ml-1">+36 30 956 6528</a>
              </p>
              <p>
                <i className="icofont-email"></i>{" "}
                <a href="mailto:s-print@csomagfutar.hu" className="ml-1">
                  s-print@csomagfutar.hu
                </a>
              </p>
              <p>
                <i className="icofont-email"></i>{" "}
                <a href="mailto:iroda@sprintlogistic.com" className="ml-1">
                iroda@sprintlogistic.com
                </a>
              </p>
              <p>
                <i className="icofont-home"></i> {" "}4400 Nyíregyháza, Tünde utca 10/A
              </p>
            </div>
            <div className="col-lg-6 col-md-12">
              <h3 className="footer-heading">Kövess minket!</h3>
              <i className="icofont-facebook"></i>
              <a href="#" className="ml-1">
                Facebook
              </a>
              <div className="logo-footer mt-4">
                <img src="images/s-print_logo1.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div className="copyright-wrap bg-navy-blue wide-tb-30">
          <div className="container">
            <div className="row text-md-left text-center">
              <div className="col-sm-12 col-md-6 copyright-links">
                <a href="aszf">ÁSZF</a> <span>|</span>{" "}
                <a href="kapcsolat">KAPCSOLAT</a>
              </div>
              <div className="col-sm-12 col-md-6 text-md-right text-center">
                Készítette:{" "}
                <a href="#" rel="nofollow">
                  Codesk Software Solutions
                </a>{" "}
                © 2021 Minden jog fenntartva
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
