import React, { useEffect } from "react";
import GetInTouch from "../../GetInTouch";

const Content = () => {
  return (
    <>
      <main id="body-content">
        <section className="bg-white wide-tb-100">
          <div className="container pos-rel">
            <div className="row">
              <div className="col-md-12 col-lg-6">
                <p>
                  <img src="images/years_img.png" alt="" className="mb-5" />
                </p>
                <p className="text-justify">
                  A 18 éve alakult S-print Logistic 24 – 48 órás belföldi szállítmányozással, fuvarozással,
                  foglalkozik az utóbbi években szolgáltatásainkat teljes körű raktárlogisztikával bővítettük,
                  valamint a 2020 – as évtől a gumiabroncs kereskedelem területén is tevékenykedünk. Cégünk
                  missziója, hogy ügyfeleink válláról a terhet levéve teljeskörűen megszervezzük bármely jellegű
                  áruk szállítását, illetve raktározását, mindezt a teljes körű minőség jegyében.
                </p>
                {/* <div className="img-icon mt-4 d-flex align-items-center">
                  <img src="images/team/team-1.jpg" />
                  <h3>
                    John Morise
                    <span>Founder & CEO</span>
                  </h3>
                </div> */}
              </div>

              <div className="w-100 spacer-50 d-none d-md-block d-lg-none d-sm-none"></div>
              <div className="col-md-12 col-lg-6">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className="icon-box-6">
                      <i className="icofont-box"></i>
                      <h3 className="h3-xs txt-blue">Raktárlogisztika</h3>
                      <p>
                        1500nm2-es raktárunkban a tároláson felül igény szerint
                        a csomagolást, címkézést és a szállítmányozást is
                        elvégezzük.
                      </p>
                      {/* <a href="/raktarlogisztika" className="btn-arrow bg-navy-blue"><i className="icofont-swoosh-right"></i></a> */}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="icon-box-6">
                      <i className="szallitmanyozas"></i>
                      <h3 className="h3-xs txt-blue">Szállítmányozás</h3>
                      <p>
                        Megbízás alapján bármely jellegű és bármekkora árut a
                        lehető leggyorsabban eljuttatjuk a kívánt helyszínre
                        Magyarországon és az Európai Unión belül.
                      </p>
                      {/* <a href="szallitmanyozas" className="btn-arrow bg-navy-blue"><i className="icofont-swoosh-right"></i></a> */}
                    </div>
                  </div>

                  <div className="w-100 spacer-50 d-none d-lg-none d-md-block d-lg-block d-sm-none"></div>

                  <div className="col-12 col-md-6">
                    <div className="icon-box-6">
                      <i className="gumiabroncs-kereskedelem"></i>
                      <h3 className="h3-xs txt-blue">
                        Gumiabroncs kereskedelem
                      </h3>
                      <p>
                        Széles termékpalettával rendelkezünk a
                        személygépjárművektől egészen a teherautókig.
                      </p>
                      {/* <a href="gumiabroncsKereskedelem" className="btn-arrow bg-navy-blue"><i className="icofont-swoosh-right"></i></a> */}
                    </div>
                  </div>

                  <div className="col-12 col-md-6">
                    <div className="icon-box-6">
                      <i className="icofont-delivery-time"></i>
                      <h3 className="h3-xs txt-blue">Fuvarozás</h3>
                      <p>
                        16 db járműből álló gépparkunk segítségével teljesen
                        lefedjük Szabolcs-Szatmár-Bereg és Hajdú-Bihar megyét.
                        Kombinált méretű csomagok egyidejű fuvarozását is
                        könnyedén megoldjuk.
                      </p>
                      {/* <a href="#" className="btn-arrow bg-navy-blue"><i className="icofont-swoosh-right"></i></a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-light-gray wide-tb-100 bg-wave">
          <div className="container pos-rel">
            <div className="row">
              <div className="col-md-6">
                <img
                  src="images/fooldalRolunk.webp"
                  className="bordered-img"
                  alt=""
                />
              </div>

              <div className="col-md-6 ml-auto why-choose">
                <h1 className="heading-main text-left">
                  {/* <span>Rólunk</span> */}
                  RÓLUNK
                </h1>

                <p className="text-justify">
                  <i>
                    “A 100%-ban magyar tulajdonú céget 2003-ban alapítottam. A kezdetekben egyszemélyes
                    vállalkozásként 24 órás speditőrködéssel foglalkoztam. Akkoriban egy kis íróasztal mellől
                    szerveztem a fuvarokat, az alapítást követő évben megvásároltam első kisteherautómat, mellyel
                    kifejezetten kis csomagokat szállítottam. <br />
                    <br /> Az idő elteltével és a piaci igényeket felmérve bővítettem a csapatot és szolgáltatásainkat, majd
                    elkezdtük a raklapos áruk szállítását is. Ma már 16 autóból álló gépparkkal, targoncákkal és 2 db
                    több mint ezer négyzetméteres raktárhelységgel rendelkezünk.”
                  </i>
                </p>
                <p>MINŐSÉG magas szinten!</p>
                <p>Nagy Imre ügyvezető</p>

                {/* <div className="skillbar-wrap mt-5">
                  <div className="clearfix">Logistics</div>
                  <div className="skillbar" data-percent="75%">
                    <div className="skillbar-percent">75%</div>
                    <div className="skillbar-bar"></div>
                  </div>
                </div>

                <div className="skillbar-wrap">
                  <div className="clearfix">Truck Rental</div>
                  <div className="skillbar" data-percent="50%">
                    <div className="skillbar-percent">50%</div>
                    <div className="skillbar-bar"></div>
                  </div>
                </div>

                <div className="skillbar-wrap">
                  <div className="clearfix">Courier</div>
                  <div className="skillbar" data-percent="95%">
                    <div className="skillbar-percent">95%</div>
                    <div className="skillbar-bar"></div>
                  </div>
                </div>
                <div className="skillbar-wrap">
                  <div className="clearfix">Air Transport</div>
                  <div className="skillbar" data-percent="60%">
                    <div className="skillbar-percent">60%</div>
                    <div className="skillbar-bar"></div>
                  </div>
                </div>
                <div className="skillbar-wrap">
                  <div className="clearfix">Support</div>
                  <div className="skillbar" data-percent="95%">
                    <div className="skillbar-percent">95%</div>
                    <div className="skillbar-bar"></div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </section>

        <section className="bg-white">
          <div className="container pos-rel">
            <div className="row">
              {/* <div className="img-business-man">
                <img src="images/courier-man.png" alt="" />
              </div> */}
              <div className="col-sm-12">
                <h1 className="heading-main mt-4">
                  {/* <span>Our Goodness</span> */}
                  Miért minket válasszon?
                </h1>
              </div>
              <div className="col-md-12 ml-auto whyChooseUs">
                <div className="row">
                  <div className="col-12 ">
                    <div className="icon-box-3 mb-5 bg-sky-blue">
                      <div className="media">
                        <div className="service-icon mr-5">
                          <i className="bg-white"></i>
                          <i className="teljeskoru-szolgaltatas"></i>
                        </div>
                        <div className="service-inner-content media-body">
                          <h4 className="h4-md">Teljeskörű szolgáltatás</h4>
                          <p className="text-justify">
                            A teljes szállítási folyamat leszervezését és megvalósítását vállaljuk. Célunk, hogy ügyfeleink
                            válláról levegyük a terhet, és minden lépést mi menedzseljünk! Önnek nincs más dolga, mint
                            megbízni minket és várni küldeménye érkezését.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 ">
                    <div className="icon-box-3 mb-5 bg-sky-blue">
                      <div className="media">
                        <div className="service-icon mr-5">
                          <i className="bg-white"></i>
                          <i className="rugalmassag"></i>
                        </div>
                        <div className="service-inner-content media-body">
                          <h4 className="h4-md">Rugalmasság</h4>
                          <p className="text-justify">
                            Igyekszünk megbízóink bármely jellegű kérését a lehető leggyorsabban teljesíteni. Akár több
                            különböző méretű áru egyidejű szállítását is megszervezzük, legyen szó kiscsomagról vagy akár
                            komplett kamiont igénylő küldeményről, garantáljuk, hogy a legkedvezőbb megoldást kínáljuk
                            Önnek. Forduljon hozzánk bizalommal egyedi igény esetén is!
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 ">
                    <div className="icon-box-3 mb-5 bg-sky-blue">
                      <div className="media">
                        <div className="service-icon mr-5">
                          <i className="bg-white"></i>
                          <i className="teljes-eu-lefedettseg"></i>
                        </div>
                        <div className="service-inner-content media-body">
                          <h4 className="h4-md">Teljes EU lefedettség</h4>
                          <p className="text-justify">
                            Fő profilunk a belföldi 24 – 48 órás gyűjtő szállítmányozás, fuvarozás mellett az Európai Unión
                            területén belül is leszervezzük export – import fuvarjait.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 ">
                    <div className="icon-box-3 bg-sky-blue">
                      <div className="media">
                        <div className="service-icon mr-5">
                          <i className="bg-white"></i>
                          <i className="minosegi-szolgaltatas"></i>
                        </div>
                        <div className="service-inner-content media-body">
                          <h4 className="h4-md">Minőségi szolgáltatás</h4>
                          <p className="text-justify">
                            Szolgáltatásainkért felelősséget vállalunk! Megbízóinkkal, ügyfeleinkkel nyíltan és valós időben
                            kommunikálunk, esetlegesen felmerülő akadály, nehézség esetén mindent megteszünk, hogy
                            megoldjuk azt.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <section className="wide-tb-100 bg-scroll counter-bg pos-rel">
          <div className="bg-overlay blue opacity-50"></div>
          <div className="container">
            <div className="row">
              <div className="col counter-style-1 col-6 col-lg-3 col-sm-6 ">
                <p>
                  <i className="icofont-google-map"></i>
                </p>
                <span className="counter">15</span>
                <div>Our Locations</div>
              </div>

              <div className="col counter-style-1 col-6 col-lg-3 col-sm-6 ">
                <p>
                  <i className="icofont-globe"></i>
                </p>
                <span className="counter">110</span>
                <span>+</span>
                <div>Clients Worldwide</div>
              </div>

              <div className="w-100 d-none d-sm-block d-lg-none spacer-60"></div>

              <div className="col counter-style-1 col-6 col-lg-3 col-sm-6 ">
                <p>
                  <i className="icofont-vehicle-delivery-van"></i>
                </p>
                <span className="counter">240</span>
                <span>+</span>
                <div>Owned Vehicles</div>
              </div>

              <div className="col counter-style-1 col-6 col-lg-3 col-sm-6 s">
                <p>
                  <i className="icofont-umbrella-alt"></i>
                </p>
                <span className="counter">2340</span>
                <div>Tonnes Transported</div>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="bg-white wide-tb-100">
          <div className="container pos-rel">
            <div className="row">
              <div className="col-sm-12 ">
                <h1 className="heading-main">
                  <span>our Gallery</span>
                  Photo Showcase
                </h1>
              </div>
            </div>

            <div id="js-styl2-mosaic" className="cbp">
              <div className="cbp-item design">
                <div className="gallery-link">
                  <a href="projectsingle" className="txt-white">
                    <i className="icofont-external-link"></i>
                  </a>
                </div>
                <a
                  href="images/portfolio/fullwidth/img-1.jpg"
                  className="cbp-caption cbp-lightbox"
                  data-title="Lorem ipsum"
                >
                  <div className="cbp-caption-defaultWrap">
                    <img src="images/portfolio/fullwidth/img-1.jpg" alt="" />
                  </div>
                  <div className="cbp-caption-activeWrap">
                    <div className="cbp-l-caption-alignCenter">
                      <div className="cbp-l-caption-body">
                        <i className="icofont-search icofont-2x txt-white"></i>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="cbp-item design">
                <div className="gallery-link">
                  <a href="projectsingle" className="txt-white">
                    <i className="icofont-external-link"></i>
                  </a>
                </div>
                <a
                  href="images/portfolio/fullwidth/img-2.jpg"
                  className="cbp-caption cbp-lightbox"
                  data-title="Lorem ipsum
            "
                >
                  <div className="cbp-caption-defaultWrap">
                    <img src="images/portfolio/fullwidth/img-2.jpg" alt="" />
                  </div>
                  <div className="cbp-caption-activeWrap">
                    <div className="cbp-l-caption-alignCenter">
                      <div className="cbp-l-caption-body">
                        <i className="icofont-search icofont-2x txt-white"></i>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="cbp-item illustration">
                <div className="gallery-link">
                  <a href="projectsingle" className="txt-white">
                    <i className="icofont-external-link"></i>
                  </a>
                </div>
                <a
                  href="images/portfolio/fullwidth/img-3.jpg"
                  className="cbp-caption cbp-lightbox"
                  data-title="Lorem ipsum
            "
                >
                  <div className="cbp-caption-defaultWrap">
                    <img src="images/portfolio/fullwidth/img-3.jpg" alt="" />
                  </div>
                  <div className="cbp-caption-activeWrap">
                    <div className="cbp-l-caption-alignCenter">
                      <div className="cbp-l-caption-body">
                        <i className="icofont-search icofont-2x txt-white"></i>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="cbp-item photography">
                <div className="gallery-link">
                  <a href="projectsingle" className="txt-white">
                    <i className="icofont-external-link"></i>
                  </a>
                </div>
                <a
                  href="images/portfolio/fullwidth/img-7.jpg"
                  className="cbp-caption cbp-lightbox"
                  data-title="Lorem ipsum
            "
                >
                  <div className="cbp-caption-defaultWrap">
                    <img src="images/portfolio/fullwidth/img-7.jpg" alt="" />
                  </div>
                  <div className="cbp-caption-activeWrap">
                    <div className="cbp-l-caption-alignCenter">
                      <div className="cbp-l-caption-body">
                        <i className="icofont-search icofont-2x txt-white"></i>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="cbp-item identity">
                <div className="gallery-link">
                  <a href="projectsingle" className="txt-white">
                    <i className="icofont-external-link"></i>
                  </a>
                </div>
                <a
                  href="images/portfolio/fullwidth/img-8.jpg"
                  className="cbp-caption cbp-lightbox"
                  data-title="Lorem ipsum
            "
                >
                  <div className="cbp-caption-defaultWrap">
                    <img src="images/portfolio/fullwidth/img-8.jpg" alt="" />
                  </div>
                  <div className="cbp-caption-activeWrap">
                    <div className="cbp-l-caption-alignCenter">
                      <div className="cbp-l-caption-body">
                        <i className="icofont-search icofont-2x txt-white"></i>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="cbp-item business">
                <div className="gallery-link">
                  <a href="projectsingle" className="txt-white">
                    <i className="icofont-external-link"></i>
                  </a>
                </div>
                <a
                  href="images/portfolio/fullwidth/img-9.jpg"
                  className="cbp-caption cbp-lightbox"
                  data-title="Lorem ipsum
            "
                >
                  <div className="cbp-caption-defaultWrap">
                    <img src="images/portfolio/fullwidth/img-9.jpg" alt="" />
                  </div>
                  <div className="cbp-caption-activeWrap">
                    <div className="cbp-l-caption-alignCenter">
                      <div className="cbp-l-caption-body">
                        <i className="icofont-search icofont-2x txt-white"></i>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="cbp-item photography">
                <div className="gallery-link">
                  <a href="projectsingle" className="txt-white">
                    <i className="icofont-external-link"></i>
                  </a>
                </div>
                <a
                  href="images/portfolio/fullwidth/img-10.jpg"
                  className="cbp-caption cbp-lightbox"
                  data-title="Lorem ipsum"
                >
                  <div className="cbp-caption-defaultWrap">
                    <img src="images/portfolio/fullwidth/img-10.jpg" alt="" />
                  </div>
                  <div className="cbp-caption-activeWrap">
                    <div className="cbp-l-caption-alignCenter">
                      <div className="cbp-l-caption-body">
                        <i className="icofont-search icofont-2x txt-white"></i>
                      </div>
                    </div>
                  </div>
                </a>
              </div>

              <div className="cbp-item business">
                <div className="gallery-link">
                  <a href="projectsingle" className="txt-white">
                    <i className="icofont-external-link"></i>
                  </a>
                </div>
                <a
                  href="images/portfolio/fullwidth/img-11.jpg"
                  className="cbp-caption cbp-lightbox"
                  data-title="Lorem ipsum"
                >
                  <div className="cbp-caption-defaultWrap">
                    <img src="images/portfolio/fullwidth/img-11.jpg" alt="" />
                  </div>
                  <div className="cbp-caption-activeWrap">
                    <div className="cbp-l-caption-alignCenter">
                      <div className="cbp-l-caption-body">
                        <i className="icofont-search icofont-2x txt-white"></i>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section className="bg-sky-blue wide-tb-100 pb-5 why-choose">
          <div className="container pos-rel">
            <div className="contact-map-bg">
              <img src="images/map-bg.png" alt="" />
            </div>
            <div className="row piecharts" id="pie-charts">
              <div className="col-sm-12 ">
                <h1 className="heading-main">
                  <span>Looking for more?</span>
                  Watch Our Intro Video
                </h1>
              </div>
              <div className="col-md-6 offset-md-3">
                <div className="row">
                  <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                    <span className="chart" data-percent="90">
                      <span className="percent"></span>
                    </span>
                    <div className="skill-name">Road Transport</div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-6">
                    <span className="chart" data-percent="90">
                      <span className="percent"></span>
                    </span>
                    <div className="skill-name">Logistics</div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                    <span className="chart" data-percent="95">
                      <span className="percent"></span>
                    </span>
                    <div className="skill-name">Truck Rental</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        {/* <section className="wide-tb-60 p-0 bg-sky-blue">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-10 offset-md-1">
                <div className="bg-fixed pos-rel video-popup">
                  <div className="bg-overlay black opacity-50"></div>
                  <div className="zindex-fixed pos-rel">
                    <a href="#" className="play-video">
                      <i className="icofont-play icofont-4x"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="mb-spacer-md">
          <div className="container wide-tb-100 pb-0">
            <div className="row">
              <div className="col-sm-12">
                <h1 className="heading-main">Rólunk mondták</h1>
              </div>
              <div className="col-sm-12">
                <div
                  className="owl-carousel owl-theme"
                  id="home-client-testimonials"
                >
                  <div className="item">
                    <div className="client-testimonial bg-wave">
                      <div className="media">
                        {/* <div className="client-testimonial-icon rounded-circle bg-navy-blue">
                          <img src="images/team_1.jpg" alt="" />
                        </div> */}
                        <div className="client-inner-content media-body">
                          <p>
                            Köszönöm az S-print Logisticnek, hogy mindent
                            megszerveznek helyettem, és mindig időben
                            leszállítják a csomagomat!{" "}
                          </p>
                          <footer className="blockquote-footer">
                            <cite title="Source Title">H. László</cite>
                          </footer>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="item">
                    <div className="client-testimonial bg-wave">
                      <div className="media">
                        {/* <div className="client-testimonial-icon rounded-circle bg-navy-blue">
                          <img src="images/team_3.jpg" alt="" />
                        </div> */}
                        <div className="client-inner-content media-body">
                          <p>
                            Ezentúl csak Veletek intézem a csomagjaim küldését!
                            <br />
                            Nagyon megbízható cég.
                            <br />
                            Mindenkinek ajánlom!{" "}
                          </p>
                          <footer className="blockquote-footer">
                            <cite title="Source Title">K. Péter</cite>
                          </footer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <GetInTouch />

        {/* <section className="wide-tb-100">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h1 className="heading-main">
                  <span>COMPANY'S NEWS</span>
                  Recent Posts
                </h1>
              </div>

              <div className="col-sm-12 col-md-4 ">
                <div className="blog-warp">
                  <img src="images/blog_img_1.jpg" alt="" className="rounded" />
                  <div className="meta-box">
                    <a href="#">Business</a> <span>/</span> September 28, 2018
                  </div>
                  <h4 className="h4-md mb-3">
                    <a href="#">Freight Payment and Auditing Services</a>
                  </h4>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantiumg
                  </p>
                </div>
              </div>

              <div className="col-sm-12 col-md-4 ">
                <div className="blog-warp">
                  <img src="images/blog_img_2.jpg" alt="" className="rounded" />
                  <div className="meta-box">
                    <a href="#">Business</a> <span>/</span> September 28, 2018
                  </div>
                  <h4 className="h4-md mb-3">
                    <a href="#">Freight Payment and Auditing Services</a>
                  </h4>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantiumg
                  </p>
                </div>
              </div>

              <div className="col-sm-12 col-md-4 ">
                <div className="blog-warp">
                  <img src="images/blog_img_3.jpg" alt="" className="rounded" />
                  <div className="meta-box">
                    <a href="#">Business</a> <span>/</span> September 28, 2018
                  </div>
                  <h4 className="h4-md mb-3">
                    <a href="#">Freight Payment and Auditing Services</a>
                  </h4>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantiumg
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="wide-tb-100 bg-fixed clients-bg pos-rel">
          <div className="bg-overlay blue opacity-80"></div>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <h1 className="heading-main">
                  <span>SOME OF OUR</span>
                  Clients
                </h1>
              </div>

              <div className="col-sm-12 ">
                <div className="owl-carousel owl-theme" id="home-clients">
                  <div className="item">
                    <img src="images/clients/client1.png" alt="" />
                  </div>

                  <div className="item">
                    <img src="images/clients/client2.png" alt="" />
                  </div>

                  <div className="item">
                    <img src="images/clients/client3.png" alt="" />
                  </div>

                  <div className="item">
                    <img src="images/clients/client4.png" alt="" />
                  </div>

                  <div className="item">
                    <img src="images/clients/client5.png" alt="" />
                  </div>

                  <div className="item">
                    <img src="images/clients/client6.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="map-bg">
          {/* <div className="contact-details row d-flex">
            <div className="col">
              <h4>Nyiregyháza</h4>
              <p>
                <i className="icofont-phone"></i>
                <a href="tel:+36309032105">+36 30 903 2105</a>
              </p>
              <div className="text-nowrap">
                <i className="icofont-email"></i>{" "}
                <a href="mailto:s-print@csomagfutar.hu">
                  s-print@csomagfutar.hu
                </a>
              </div>
            </div>
          </div> */}
          <div id="map-holder" className="pos-rel">
            <iframe
              title="google-map"
              width="100%"
              height="400px"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=4400%20Ny%C3%ADregyh%C3%A1za,%20T%C3%BCnde%20utca%2010/A&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
        </section>
      </main>
    </>
  );
};
export default Content;
