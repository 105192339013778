import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Content from "./Content";
import Breadcrumbs from "../Breadcrumbs";
import BackToTop from "../BackToTop";
import RequestModal from "../RequestModal";
import BreadcrumbsData from "../breadcrumbs.json";
import { Helmet } from "react-helmet";

const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Kapcsolat - S-Print Logistic</title>
      </Helmet>
      <Header />
      <Breadcrumbs
        title={BreadcrumbsData.contact.title}
        description={BreadcrumbsData.contact.description}
      />
      <Content />
      <Footer />
      <RequestModal />
      <BackToTop />
    </>
  );
};
export default Contact;
