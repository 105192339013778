import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import PageContent from "./content";
import { Helmet } from "react-helmet";

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Főoldal - S-Print Logistic</title>
      </Helmet>
      <Header />
      <PageContent />
      <Footer />
    </>
  );
};
export default Home;
