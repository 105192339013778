import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import Content from "./Content";
import Breadcrumbs from "../Breadcrumbs";
import BackToTop from "../BackToTop";
import RequestModal from "../RequestModal";
import BreadcrumbsData from "../breadcrumbs.json";

const NotFound = () => {
  return (
    <>
      <Header />
      <Breadcrumbs
        title={BreadcrumbsData.notfound.title}
        description={BreadcrumbsData.notfound.description}
      />
      <Content />
      <Footer />
      <RequestModal />
      <BackToTop />
    </>
  );
};
export default NotFound;
