import React from "react";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";
import { Formik } from "formik";

const Content = () => {
  let toastId = null;
  const displayToast = (message) => {
    if (!toast.isActive(toastId)) {
      toastId = toast(message, {
        closeOnClick: false,
        progressClassName: "toastProgress",
        toastId: "my_toast",
        autoClose: true,
        closeButton: false,
        position: "top-right",
      });
    }
  };
  return (
    <>
      <main id="body-content">
        <section className="wide-tb-80 contact-full-shadow">
          <div className="container">
            <div className="contact-map-bg">
              <img src="images/map-bg.png" alt="" />
            </div>
            <div className="row justify-content-center">
              <div className="col-md-6 col-sm-12 col-lg-4 s">
                <div className="contact-detail-shadow">
                  <h4>Magyarország</h4>
                  <div className="d-flex align-items-start items">
                    <i className="icofont-google-map"></i>{" "}
                    <span>4400 Nyíregyháza, Tünde utca 10/A</span>
                  </div>
                  <div className="d-flex align-items-start items">
                    <i className="icofont-phone"></i>{" "}
                    Iroda:&nbsp;
                    <a href="tel:+36309566529">+36 30 956 6529</a>
                  </div>
                  <div className="d-flex align-items-start items">
                    <i className="icofont-phone"></i>{" "}
                    Futárszervezés:&nbsp;
                    <a href="tel:+36309566528">+36 30 956 6528</a>
                  </div>
                  <div className="text-nowrap d-flex align-items-start items">
                    <i className="icofont-email"></i>{" "}
                    <a href="mailto:s-print@csomagfutar.hu">
                    s-print@csomagfutar.hu
                    </a>
                  </div>
                  <div className="text-nowrap d-flex align-items-start items">
                    <i className="icofont-email"></i>{" "}
                    <a href="mailto:iroda@sprintlogistic.com">
                    iroda@sprintlogistic.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="wide-tb-100 bg-light-gray pt-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-sm-12 col-lg-8 offset-lg-2 ">
                <div className="free-quote-form contact-page">
                  <h1 className="heading-main mb-4">
                    Lépjen velünk kapcsolatba!
                  </h1>
                  <Formik
                    initialValues={{
                      lastname: "",
                      firstname: "",
                      phone: "",
                      email: "",
                      message: "",
                    }}
                    enableReinitialize
                    onSubmit={(values, { setSubmitting }) => {
                      console.log(values);
                      emailjs
                        .send(
                          "service_96z78vs",
                          "template_1ej1e4i",
                          {
                            name: "S-Print Logistic",
                            from_name: values.lastname + " " + values.firstname,
                            message: values.message,
                            reply_to:
                              values.email +
                              " vagy hívd fel telefonon: " +
                              values.phone,
                            site_name: "https://sprintlogistic.com",
                            to_email: "iroda@sprintlogistic.com",
                            from_email: "postmaster@sprintlogistic.com",
                          },
                          "user_LrB06JmtNSOzWeUdvPyEj"
                        )
                        .then(() => {
                          displayToast(
                            "Üzenet elküldve!\nKöszönjük leveledet!\nKis türelmedet, hamarosan válaszolunk."
                          );
                          setSubmitting(false);
                        })
                        .catch((e) => {
                          displayToast(
                            "Sikertelen küldés!\nPróbáld újra később!"
                          );
                          setSubmitting(false);
                          console.log(e);
                        });
                    }}
                  >
                    {({
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      values,
                      isSubmitting,
                    }) => (
                      <form
                        action="#"
                        method="post"
                        id="contactusForm"
                        noValidate="novalidate"
                        className="col rounded-field"
                      >
                        <div className="form-row mb-4">
                          <div className="col">
                            <input
                              type="text"
                              name="lastname"
                              id="name"
                              className="form-control"
                              placeholder="Vezetéknév"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.lastname}
                            />
                          </div>
                          <div className="col">
                            <input
                              type="text"
                              name="email"
                              id="email"
                              className="form-control"
                              placeholder="Keresztnév"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.firstname}
                            />
                          </div>
                        </div>
                        <div className="form-row mb-4">
                          <div className="col">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              className="form-control"
                              placeholder="Email cím"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.email}
                            />
                          </div>
                          <div className="col">
                            <input
                              type="text"
                              name="name"
                              id="name"
                              className="form-control"
                              placeholder="Telefonszám"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.phone}
                            />
                          </div>
                        </div>
                        <div className="form-row mb-4">
                          <div className="col">
                            <textarea
                              rows="7"
                              name="cment"
                              id="cment"
                              placeholder="Írja íde az üzenetet..."
                              className="form-control"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              value={values.message}
                            ></textarea>
                          </div>
                        </div>
                        <div className="form-row text-center">
                          <button
                            name="contactForm"
                            id="contactForm"
                            type="submit"
                            className="form-btn mx-auto btn-theme bg-orange"
                          >
                            Üzenet küldése{" "}
                            <i className="icofont-rounded-right"></i>
                          </button>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="map-bg">
          <div id="map-holder" className="pos-rel">
            <iframe
              title="google-map"
              width="100%"
              height="400px"
              id="gmap_canvas"
              src="https://maps.google.com/maps?q=4400%20Ny%C3%ADregyh%C3%A1za,%20T%C3%BCnde%20utca%2010/A&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
            ></iframe>
          </div>
        </section>
      </main>
    </>
  );
};
export default Content;
