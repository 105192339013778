import React from "react";
import Slider from "./Slilder";
import Content from "./Content";
// import VideoBox from "./VideoBox";

const PageContent = () => {
  return (
    <>
      <Slider />
      <Content />
      {/* <RequestModal /> */}
    </>
  );
};
export default PageContent;
