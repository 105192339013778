import React from "react";

const Services = () => {
  return (
    <>
      <main id="body-content">
        <section className="wide-tb-80 bg-fixed what-we-offer">
          <div className="container pos-rel">
            <div className="row align-items-center">
              <div className="col-md-6">
                {/* <h2 className="mb-4 fw-7 txt-blue ">
                                    Quality <span className="fw-6 txt-orange">and</span> Performance <br /><span className="fw-6 txt-orange">at the right price</span>
                                </h2> */}
                <p className="">
                    Megbízás alapján leszervezzük és eljuttatjuk az árut A pontból B pontba saját vagy alvállalkozó
                    partnereink segítségével. Kis, közepes, nagy raklapos vagy komplett kamiont igénylő
                    küldemények akár kombinált szállítását is könnyen megoldjuk! Teljes magyarországi és EU-s
                    lefedettség. Célunk, hogy ügyfeleink helyett a teljes szállítási folyamatot megszervezzük és
                    megvalósítsuk.
                </p>
              </div>
              <div className="col-md-6"></div>
            </div>
          </div>
        </section>
        {/* <section className="bg-white wide-tb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="heading-main">
                                    <span>what we offer</span>
                                    Our Main Services
                                </h1>
                            </div>
                            <div className="col-md-4 ">
                                <a href="service-details.html">
                                    <div className="icon-box-1">
                                        <img src="images/icon-box-1.jpg" alt="" />
                                        <div className="text">
                                            <i className="icofont-vehicle-delivery-van"></i>
                                            GROUND DELIVERY
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4 ">
                                <a href="service-details.html">
                                    <div className="icon-box-1">
                                        <img src="images/icon-box-2.jpg" alt="" />
                                        <div className="text">
                                            <i className="icofont-airplane-alt"></i>
                                            AIR DELIVERY
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="col-md-4 ">
                                <a href="service-details.html">
                                    <div className="icon-box-1">
                                        <img src="images/icon-box-3.jpg" alt="" />
                                        <div className="text">
                                            <i className="icofont-ship"></i>
                                            SEA DELIVERY
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-sky-blue wide-tb-100">
                    <div className="container pos-rel">
                        <div className="row">
                            <div className="img-business-man">
                                <img src="images/courier-man.png" alt="" />
                            </div>
                            <div className="col-sm-12">
                                <h1 className="heading-main">
                                    <span>Our Goodness</span>
                                    What Makes Us Special
                                </h1>
                            </div>
                            <div className="col-md-6 ml-auto">
                                <div className="row">
                                    <div className="col-12 ">
                                        <div className="icon-box-3 mb-5 bg-sky-blue">
                                            <div className="media">
                                                <div className="service-icon mr-5">
                                                    <i className="icofont-box"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md">Packaging and Storage</h4>
                                                    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae. Praesent pellentesque diam vitae nibh aliquam faucibus.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 ">
                                        <div className="icon-box-3 mb-5 bg-sky-blue">
                                            <div className="media">
                                                <div className="service-icon mr-5">
                                                    <i className="icofont-shield"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md">Safety & Quality</h4>
                                                    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae. Praesent pellentesque diam vitae nibh aliquam faucibus.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 ">
                                        <div className="icon-box-3 bg-sky-blue">
                                            <div className="media">
                                                <div className="service-icon mr-5">
                                                    <i className="icofont-tree-alt"></i>
                                                </div>
                                                <div className="service-inner-content media-body">
                                                    <h4 className="h4-md">Care for Environment</h4>
                                                    <p>Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae. Praesent pellentesque diam vitae nibh aliquam faucibus.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wide-tb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-7 ml-lg-auto pos-rel col-md-12">
                                <h1 className="heading-main text-left">
                                    <span>get updates</span>
                                    Tracking Your Freight
                                </h1>
                                <form className="form-inline tracking">
                                    <input type="text" className="form-control mb-2 mr-sm-2 col" placeholder="Enter order number" />
                                    <button type="submit" className="btn btn-theme bg-orange mb-2 ml-3">Check Now <i className="icofont-rounded-right"></i></button>
                                </form>
                                <div className="forklift-image ">
                                    <img src="images/forklift_Image.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="bg-light-gray">
                    <div className="container-fluid p-0">
                        <div className="row align-items-center no-gutters">
                            <div className="col-lg-4 text-center">
                                <div className="px-5 wide-tb-100">
                                    <div className="service-icon mx-auto mb-5 icon-box-5">
                                        <i className="icofont-glass"></i>
                                    </div>
                                    <h4 className="h4-md fw-7 txt-blue">SAFE & SECURE</h4>
                                    Nunc non mollis nulla. Sed tconsectetur elit id mi consectetur bibendum.
                                </div>
                            </div>
                            <div className="col-lg-4 text-center bg-fixed clients-bg pos-rel txt-white">
                                <div className="bg-overlay black opacity-40"></div>
                                <div className="px-5 wide-tb-100" style={{ position: "relative", zIndex: "999" }}>
                                    <div className="service-icon mx-auto mb-5 icon-box-5">
                                        <i className="icofont-delivery-time"></i>
                                    </div>
                                    <h4 className="h4-md fw-7">FAST DELIVERY</h4>
                                    Nunc non mollis nulla. Sed tconsectetur elit id mi consectetur bibendum.
                                </div>
                            </div>
                            <div className="col-lg-4 text-center">
                                <div className="px-5 wide-tb-100">
                                    <div className="service-icon mx-auto mb-5 icon-box-5">
                                        <i className="icofont-live-support"></i>
                                    </div>
                                    <h4 className="h4-md fw-7 txt-blue">24/7 Support</h4>
                                    Nunc non mollis nulla. Sed tconsectetur elit id mi consectetur bibendum.
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wide-tb-100">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="heading-main">
                                    <span>What Our</span>
                                    Customers Saying
                                </h1>
                            </div>
                            <div className="col-sm-12">
                                <div className="owl-carousel owl-theme" id="home-client-testimonials">
                                    <div className="item">
                                        <div className="client-testimonial bg-wave">
                                            <div className="media">
                                                <div className="client-testimonial-icon rounded-circle bg-navy-blue">
                                                    <img src="images/team_1.jpg" alt="" />
                                                </div>
                                                <div className="client-inner-content media-body">
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Aliquam gravida, urna quis ornare imperdiet, </p>
                                                    <footer className="blockquote-footer"><cite title="Source Title">John Gerry  Design Hunt</cite></footer>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="client-testimonial bg-wave">
                                            <div className="media">
                                                <div className="client-testimonial-icon rounded-circle bg-navy-blue">
                                                    <img src="images/team_2.jpg" alt="" />
                                                </div>
                                                <div className="client-inner-content media-body">
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Aliquam gravida, urna quis ornare imperdiet, </p>
                                                    <footer className="blockquote-footer"><cite title="Source Title">John Gerry  Design Hunt</cite></footer>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="client-testimonial bg-wave">
                                            <div className="media">
                                                <div className="client-testimonial-icon rounded-circle bg-navy-blue">
                                                    <img src="images/team_3.jpg" alt="" />
                                                </div>
                                                <div className="client-inner-content media-body">
                                                    <p>Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Aliquam gravida, urna quis ornare imperdiet, </p>
                                                    <footer className="blockquote-footer"><cite title="Source Title">John Gerry  Design Hunt</cite></footer>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="wide-tb-100 bg-sky-blue pos-rel">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1 className="heading-main">
                                    <span>SOME OF OUR</span>
                                    Clients
                                </h1>
                            </div>
                            <div className="col-sm-12">
                                <div className="owl-carousel owl-theme" id="home-clients">
                                    <div className="item bg-white">
                                        <img src="images/clients/client1.png" alt="" />
                                    </div>
                                    <div className="item bg-white">
                                        <img src="images/clients/client2.png" alt="" />
                                    </div>
                                    <div className="item bg-white">
                                        <img src="images/clients/client3.png" alt="" />
                                    </div>
                                    <div className="item bg-white">
                                        <img src="images/clients/client4.png" alt="" />
                                    </div>
                                    <div className="item bg-white">
                                        <img src="images/clients/client5.png" alt="" />
                                    </div>
                                    <div className="item bg-white">
                                        <img src="images/clients/client6.png" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
      </main>
    </>
  );
};
export default Services;
