import React from "react";
import { Helmet } from "react-helmet";

const Services = () => {
  return (
    <>
      <Helmet>
        <title>ÁSZF - S-Print Logistic</title>
      </Helmet>
      <main id="body-content">
        <section className="wide-tb-80 bg-fixed">
          <div className="container pos-rel">
            <div className="row align-items-center">
              <div className="col-md-12 txt-blue">
                <h2 className="mb-4 fw-7 txt-blue ">
                  Adatvédelmi és adatkezelési szabályzat
                </h2>
                <ol>
                  <li>
                    <p className="text-justify">Általános rendelkezések</p>
                  </li>
                  <li>
                    <p className="text-justify">
                      A jelen tájékoztatásban meghatározásra kerülnek azok az
                      adatvédelmi és adatkezelési szabályok, amelyeket az{" "}
                      <a href="http://www.sprintlogistic.hu" target="_blank">
                        {" "}
                        www.sprintlogistic.hu
                      </a>{" "}
                      internetes portált kiadó és üzemeltető{" "}
                      <b>S-Print Logistic Bt.</b> (székhely: 4400 Nyíregyháza,
                      Tüzér út 16. cégjegyzékszám: 15-06-092293 adószám:
                      21866142-2-15, a továbbiakban: Adatkezelő) az Európai
                      Parlament és a Tanács 2016/679. számú rendeletében
                      (Általános Adatvédelmi Rendelet, a továbbiakban: GDPR), az
                      információs önrendelkezési jogról és az
                      információszabadságról szóló 2011. évi CXII. törvényben (a
                      továbbiakban: Infotv.), a Polgári Törvénykönyvről szóló
                      2013. évi V. törvényben (a továbbiakban: Ptk.) foglalt
                      rendelkezésekre figyelemmel magára nézve kötelezőnek ismer
                      el.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Jelen Tájékoztató az Adatkezelő weboldalán, valamint mobil
                      vagy táblagép alkalmazásában a Felhasználókról gyűjtött
                      személyes adatok kezelésének elveit tartalmazza.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      A jelen tájékoztató értelmezésében adatkezelő
                      tevékenységet végez és ezen tevékenységéhez kapcsolódóan
                      biztosítja az ehhez szükséges informatikai
                      szolgáltatásokat.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      A jelen tájékoztató értelmezésében Felhasználó az, aki az{" "}
                      <a href="http://www.sprintlogistic.hu" target="_blank">
                        {" "}
                        www.sprintlogistic.hu
                      </a>{" "}
                      weboldalon, valamint mobil vagy táblagép alkalmazásában{" "}
                      <b>S-Print Logistic Bt.</b> szolgáltatását igénybe veszi
                      (azaz a honlapra belép, adatlapot kitölt), az{" "}
                      <a href="http://www.sprintlogistic.hu" target="_blank">
                        {" "}
                        www.sprintlogistic.hu
                      </a>{" "}
                      egyes hírleveleire feliratkozik, illetve felveszi a
                      kapcsolatot az Adatkezelővel (a továbbiakban:
                      Felhasználó).
                    </p>
                  </li>
                </ol>
                <h2>
                  Az Adatkezelő főtevékenysége végzése során kezelt adatok köre
                </h2>
                <ul>
                  <p className="text-justify">
                    <b>
                      Az{" "}
                      <a href="http://www.sprintlogistic.hu" target="_blank">
                        {" "}
                        www.sprintlogistic.hu
                      </a>{" "}
                      weblapra történő belépés, illetve a weboldal
                      szolgáltatásainak igénybevétele során kezelt adatok:
                    </b>
                  </p>
                  <ul>
                    <li>
                      <p className="text-justify">
                        A belépés során technikailag automatikusan rögzítésre
                        kerülhetnek egyes adatok, melyek a látogatás folyamán
                        generálódnak. Az automatikusan rögzítésre kerülő
                        adatokat a rendszer a Felhasználó külön nyilatkozata
                        vagy cselekménye nélkül a belépéskor, illetve kilépéskor
                        automatikusan naplózza. A rendszer ezeket az adatokat a
                        szolgáltatás nyújtásához, a működés biztonságához
                        szükséges legrövidebb ideig, ezt követően az adatok
                        felülírásra, azaz törlésre kerülnek. Ily módon például
                        az alábbi adatok kerülhetnek tárolásra: IP cím, böngésző
                        adatok, látogatási paraméterek.
                      </p>
                    </li>
                    <li>
                      <p className="text-justify">
                        Az Adatkezelő az optimális, egyénre szabott megjelenés
                        érdekében a Felhasználó által használt eszközön ún.
                        cookie-t helyezhet el. A cookie lehetővé teszi az
                        edinaturally.com oldal magasabb színvonalú működésének
                        biztosítását, személyre szabott szolgáltatások
                        biztosítását. A cookie-t a Felhasználó törölni tudja a
                        saját eszközéről, illetve lehetőség van a böngésző oly
                        módon történő beállítására, hogy tiltsa a cookie-k
                        alkalmazását. A saját tevékenységi körében az Adatkezelő
                        által elhelyezett cookie-k alkalmazása révén az
                        Adatkezelő nem gyűjt adatokat, a cookie-k a Felhasználó
                        oldali kiszolgálási folyamatban (a Felhasználó eszközén
                        és böngészőjében) hasznosulnak. A cookie-k a Felhasználó
                        rendszerében határozatlan ideig, illetve a cookie-k
                        Felhasználó általi törléséig vannak jelen.
                      </p>
                    </li>
                  </ul>
                </ul>
                <ol>
                  <li>
                    <p className="text-justify">
                      Kapcsolatfelvétel az Adatkezelővel
                    </p>
                    <p className="text-justify">
                      <b>Az adatkezelés jogalapja és a kezelt adatok köre:</b>{" "}
                      Amennyiben a Felhasználó az Adatkezelővel
                      kapcsolatfelvételt kezdeményez (például e-mail vagy
                      Facebook üzenet útján), úgy tudomásul veszi és hozzájárul
                      ahhoz, hogy az Adatkezelő rögzíti a Felhasználó e-mail
                      címét.
                    </p>
                    <p className="text-justify">
                      <b>Az adatkezeléssel érintett személyek:</b> minden olyan
                      természetes személy, aki a Társaság szolgáltatásaival,
                      termékeivel kapcsolatban a e-mailen vagy Facebookon
                      keresztül információt, ajánlatot kér és megadja személyes
                      adatait.
                    </p>
                    <p className="text-justify">
                      <b>Az adatkezelés célja:</b> azonosítás, kapcsolattartás,
                      ajánlatadás
                    </p>
                    <p className="text-justify">
                      <b>Az adatkezelés időtartama:</b> az információ
                      szolgáltatását, illetve az ajánlat nyújtását követő
                      legfeljebb 1 év
                    </p>
                    <p className="text-justify">
                      <b>Az adatkezelés címzettjei:</b> ügyfélkapcsolatot ellátó
                      munkatársak, adatfeldolgozók, vezető tisztségviselő
                    </p>
                    <p className="text-justify">
                      Az Adatkezelő az adatokat <b>nem továbbítja.</b>
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      <b>Kapcsolatfelvételi űrlap kitöltése</b>
                    </p>
                    <p className="text-justify">
                      <b>Jogalap:</b> az érintett hozzájárulása, amelyet az
                      érintett az Adatkezelő honlapján a kapcsolatfelvételi
                      űrlap kitöltésével és az “üzenet küldése” gombbal ad meg.
                    </p>
                    <p className="text-justify">
                      <b>Cél:</b> (i) az Adatkezelő által szervezett eseményekre
                      történő jelentkezés
                    </p>
                    <p className="text-justify">
                      (ii) az Adatkezelő bizonyos szolgáltatásinak, termékeinek
                      megvásárlása
                    </p>
                    <p className="text-justify">
                      <b>Érintett adatok:</b> név, e-mail cím, telefonszám
                    </p>
                    <p className="text-justify">
                      <b>Érintett személyek:</b> minden olyan természetes
                      személy, aki a honlapon a fent írtak szerint üzenetet küld
                    </p>
                    <p className="text-justify">
                      <b>Időtartam:</b> a hozzájárulás visszavonásáig, de
                      legfeljebb 5 évig
                    </p>
                    <p className="text-justify">
                      <b>Címzett:</b> az Adatkezelő ügyfélkapcsolatokkal
                      foglalkozó munkatársai, adatfeldolgozók, vezető
                      tisztségviselő
                    </p>
                    <p className="text-justify">
                      Az Adatkezelő az adatokat <b>nem továbbítja.</b>
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      <b>Webanalitikai szolgáltatók</b>
                    </p>
                    <p className="text-justify">
                      A Felhasználó az Adatkezelő weboldalának használatával
                      hozzájárul az eszközéhez kapcsolódó információk jelen
                      fejezetben felsorolt külső szolgáltató által történő
                      hasznosításához.
                    </p>
                    <p className="text-justify">
                      Az Adatkezelő a Google Inc. Analytics szolgáltatását
                      használja. A Google Analytics cookie-kat használ abból a
                      célból, hogy segítse az adott honlap használatának
                      elemzését. A cookie által tárolt információkat (beleértve
                      a Felhasználó IP-címét) a Google Inc. egyesült államokbeli
                      szerverein tárolja.
                    </p>
                    <p className="text-justify">
                      A Google Inc. átadhatja a gyűjtött információkat harmadik
                      felek részére, amennyiben ezt törvény írja elő, vagy az
                      adott harmadik felek a Google Inc. megbízásából
                      feldolgozzák az információkat.
                    </p>
                    <p className="text-justify">
                      A szolgáltató által elhelyezett cookie-k bármikor
                      törölhetőek a Felhasználó készülékéről, a böngésző(k)
                      megfelelő beállításainak kiválasztásával pedig általában
                      visszautasítható a cookie-k használata. A külső
                      szolgáltatók által elhelyezett cookie-k beazonosítása az
                      adott cookie-hoz kapcsolódó domain alapján történhet. A
                      cookie-król és azok kezeléséről az alábbi weboldal
                      tartalmaz bővebb felvilágosítást.
                    </p>
                    <p className="text-justify">
                      Vannak olyan külső szolgáltatók, amelyekkel az Adatkezelő
                      nem áll szerződéses jogviszonyban vagy az adott
                      adatkezelés tekintetében nem működik együtt, azonban ettől
                      függetlenül is az edinaturally.com honlaphoz hozzáférnek a
                      megosztási opciók használata során, és ezáltal a
                      Felhasználókról adatokat gyűjthetnek, amelyek esetenként
                      alkalmasak lehetnek a Felhasználó azonosítására. Ilyen
                      külső szolgáltatók lehetnek különösen, de nem
                      kizárólagosan: Facebook Ireland LTD., Google LLC.,
                      Instagram LLC., Twitter International Company, WhatsApp
                      Inc., Viber Media LLC., Read It Later, Inc. – Ezen külső
                      szolgáltatók a részükre esetlegesen továbbított személyes
                      adatokat saját adatvédelmi irányelveik szerint kezelik.
                    </p>
                  </li>
                </ol>
                <h2>A Felhasználó jogai és kötelezettségei</h2>
                <ol>
                  <li>
                    <p className="text-justify">Tájékoztatáshoz való jog</p>
                    <p className="text-justify">
                      A Felhasználó bármikor kérheti, hogy Adatkezelő
                      tájékoztassa, hogy kezeli-e a Felhasználó személyes
                      adatát, és ha igen, akkor az általa kezelt személyes
                      adatokhoz biztosítson számára hozzáférést a Felhasználó
                      részére.
                    </p>
                    <p className="text-justify">
                      A Felhasználó a személyes adatainak kezeléséről bármikor
                      írásban, az Adatkezelő{" "}
                      <a href="mailto:s-print@csomagfutar.hu">
                        {" "}
                        s-print@csomagfutar.hu
                      </a>{" "}
                      e-mail címére küldött levélben kérhet tájékoztatást. Az
                      e-mailben küldött tájékoztatáskérést az Adatkezelő csak
                      akkor tekinti hitelesnek, ha azt a Felhasználó regisztrált
                      e-mail címéről küldik.
                    </p>
                    <p className="text-justify">
                      A tájékoztatás-kérés kiterjedhet a Felhasználónak az
                      Adatkezelő által kezelt adataira, azok forrására, az
                      Adatkezelés céljára, jogalapjára, időtartamára, az
                      esetleges Adatfeldolgozó nevére és címére, az
                      Adatkezeléssel összefüggő tevékenységekre, valamint a
                      személyes adatoknak továbbítása esetén arra, hogy kik és
                      milyen célból kapták vagy kapják meg Felhasználó adatait.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      A kezelt adatok helyesbítéséhez, illetve módosításához
                      való jog
                    </p>
                    <p className="text-justify">
                      A Felhasználó kérheti az Adatkezelő által kezelt személyes
                      adatainak helyesbítését vagy módosítását, valamint a
                      kérheti a hiányos adatok kiegészítését. A Felhasználó a
                      személyes adatainak helyesbítését, illetve módosítását
                      írásban, az Adatkezelő
                      <a href="mailto:s-print@csomagfutar.hu">
                        {" "}
                        s-print@csomagfutar.hu
                      </a>{" "}
                      e-mail címére küldött levélben kérheti. Az e-mailben
                      küldött kérést az Adatkezelő csak akkor tekinti
                      hitelesnek, ha azt a Felhasználó regisztrált e-mail
                      címéről küldik.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Személyes adatok törlése iránti kérelem
                    </p>
                    <p className="text-justify">
                      A Felhasználó kérheti az Adatkezelő által kezelt személyes
                      adatainak törlését. A törlés akkor tagadható meg, ha a
                      véleménynyilvánítás szabadságához és a tájékozódáshoz való
                      jog gyakorlása céljából az adatkezelés szükséges, ha a
                      személyes adatok kezelésére jogszabály felhatalmazást ad,
                      illetve jogi igények előterjesztéséhez, érvényesítéséhez,
                      illetve védelméhez. A törlési kérelem megtagadásáról az
                      Adatkezelő minden esetben tájékoztatja a Felhasználót,
                      megjelölve a törlés megtagadásának indokát. Személyes adat
                      törlésére irányuló igény teljesítését követően a korábbi
                      (törölt) adatok már nem állíthatók helyre. A Felhasználó a
                      személyes adatainak helyesbítését, illetve módosítását
                      írásban, az Adatkezelő{" "}
                      <a href="mailto:s-print@csomagfutar.hu">
                        {" "}
                        s-print@csomagfutar.hu
                      </a>{" "}
                      e-mail címére küldött levélben kérheti. Az e-mailben
                      küldött kérést az Adatkezelő csak akkor tekinti
                      hitelesnek, ha azt a Felhasználó regisztrált e-mail
                      címéről küldik.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Személyes adatok kezelésének korlátozása
                    </p>
                    <p className="text-justify">
                      A Felhasználó jogosult arra, hogy Személyes adatainak
                      kezelését az Adatkezelő korlátozza abban az esetben, ha a
                      Felhasználó vitatja a kezelt személyes adatok pontosságát.
                      A korlátozás arra az időtartamra vonatkozik, amely
                      lehetővé teszi, hogy az Adatkezelők ellenőrizze a kezelt
                      személyes adatok pontosságát. A korlátozás kérhető akkor
                      is, ha a Felhasználó álláspontja szerint az adatkezelés
                      jogellenes, de a Felhasználó ellenzi a kezelt személyes
                      adatok törlését. A korlátozás kérhető abban az esetben is,
                      ha az adatkezelés célja megvalósult, de a Felhasználó
                      igényli azok Adatkezelő általi kezelését jogi igények
                      előterjesztéséhez, érvényesítéséhez vagy védelméhez.
                    </p>
                    <p className="text-justify">
                      A Felhasználó a személyes adatainak korlátozását írásban,
                      az Adatkezelő{" "}
                      <a href="mailto:s-print@csomagfutar.hu">
                        {" "}
                        s-print@csomagfutar.hu
                      </a>{" "}
                      e-mail címére küldött levélben kérheti. Az e-mailben
                      küldött kérést az Adatkezelő csak akkor tekinti
                      hitelesnek, ha azt a Felhasználó e-mail címéről küldik.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Személyes adatok átadásának, továbbításának kéréséhez való
                      jog
                    </p>
                    <p className="text-justify">
                      A Felhasználó kérheti, hogy az Adatkezelők a Felhasználó
                      által rendelkezésére bocsátott és az Adatkezelő által
                      automatizált módon kezelt személyes adatokat tagolt,
                      széles körben használt, géppel olvasható formátumban
                      részére átadja és/ vagy azokat egy másik adatkezelő
                      részére továbbítsa.
                    </p>
                    <p className="text-justify">
                      A Felhasználó az ilyen irányú kérését írásban, az
                      Adatkezelő{" "}
                      <a href="mailto:s-print@csomagfutar.hu">
                        {" "}
                        s-print@csomagfutar.hu
                      </a>{" "}
                      e-mail címére küldött levélben kérheti. Az e-mailben
                      küldött kérést az Adatkezelő csak akkor tekinti
                      hitelesnek, ha azt a Felhasználó regisztrált e-mail
                      címéről küldik.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">Tiltakozáshoz való jog</p>
                    <p className="text-justify">
                      A Felhasználó tiltakozhat személyes adatainak kezelése
                      ellen akkor, ha a személyes adatok kezelése kizárólag az
                      Adatkezelőre vonatkozó jogi kötelezettség teljesítéséhez
                      vagy az Adatkezelő vagy harmadik személy jogos érdekének
                      érvényesítéséhez szükséges; ha az Adatkezelés célja
                      közvetlen üzletszerzés, vagy ha az Adatkezelésre közérdekű
                      feladat teljesítése érdekében kerül sor. Az Adatkezelő a
                      Felhasználó tiltakozásának jogszerűségét megvizsgálja, és
                      ha a tiltakozás megalapozottságát megállapítja, az
                      Adatkezelést megszünteti és a kezelt személyes adatokat
                      zárolja, továbbá a tiltakozásról és az annak alapján tett
                      intézkedésekről értesíti mindazokat, akik részére a
                      tiltakozással érintett személyes adatok esetlegesen
                      továbbításra kerültek.
                    </p>
                    <p className="text-justify">
                      A Felhasználó az ilyen irányú kérését írásban, az
                      Adatkezelő{" "}
                      <a href="mailto:s-print@csomagfutar.hu">
                        {" "}
                        s-print@csomagfutar.hu
                      </a>{" "}
                      e-mail címére küldött levélben kérheti. Az e-mailben
                      küldött kérést az Adatkezelő csak akkor tekinti
                      hitelesnek, ha azt a Felhasználó regisztrált e-mail
                      címéről küldik.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">Határidők</p>
                    <p className="text-justify">
                      Az Adatkezelő indokolatlan késedelem nélkül, de
                      mindenféleképpen a kérelem beérkezésétől számított egy
                      hónapon belül tájékoztatja a Felhasználót az 1-6. pontok
                      szerinti kérelem nyomán hozott intézkedésekről. Szükség
                      esetén, figyelembe véve a kérelem összetettségét és a
                      kérelmek számát, ez a határidő további két hónappal
                      meghosszabbítható. A határidő meghosszabbításáról az
                      Adatkezelő a késedelem okainak megjelölésével a kérelem
                      kézhezvételétől számított egy hónapon belül tájékoztatja a
                      Felhasználót. Ha a Felhasználó elektronikus úton nyújtotta
                      be a kérelmet, a tájékoztatást lehetőség szerint
                      elektronikus úton kell megadni, kivéve, ha a Felhasználó
                      azt másként kéri. Ha az Adatkezelő nem tesz intézkedéseket
                      az érintett kérelme nyomán, késedelem nélkül, de legkésőbb
                      a kérelem beérkezésétől számított egy hónapon belül
                      tájékoztatja a Felhasználót az intézkedés elmaradásának
                      okairól, valamint arról, hogy a Felhasználó panaszt
                      nyújthat be a felügyeleti hatóságnál, és élhet bírósági
                      jogorvoslati jogával.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">Panaszhoz való jog</p>
                    <p className="text-justify">
                      A Felhasználó az adatkezeléssel kapcsolatos panaszával
                      közvetlenül a Nemzeti Adatvédelmi és Információszabadság
                      Hatósághoz (cím:1055 Budapest Falk Miksa utca 9-11.
                      telefon: +36-1-391- 1400; e-mail:{" "}
                      <a href="mailto:ugyfelszolgalat@naih.hu">
                        {" "}
                        ugyfelszolgalat@naih.hu
                      </a>
                      ; honlap:
                      <a href="http://www.naih.hu" target="_blank">
                        {" "}
                        www.naih.hu
                      </a>
                      ) fordulhat.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">Bírósághoz fordulás joga</p>
                    <p className="text-justify">
                      A Felhasználó jogainak megsértése esetén bírósághoz
                      fordulhat. A per elbírálása a törvényszék hatáskörébe
                      tartozik. A per – az érintett választása szerint – a
                      Felhasználó lakóhelye vagy tartózkodási helye szerinti
                      törvényszék előtt is megindítható.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      A Felhasználó kötelezettségei
                    </p>
                    <p className="text-justify">
                      A Felhasználó szavatol azért, hogy az általa más
                      természetes személyekről megadott vagy hozzáférhetővé tett
                      személyes adat kezeléséhez az érintett természetes személy
                      hozzájárulását jogszerűen beszerezte.
                    </p>
                    <p className="text-justify">
                      Bármely Felhasználó e-mail címének valamint a regisztráció
                      során megadott adatainak megadásakor felelősséget vállal
                      azért, hogy a megadott e-mail címről, illetve az általa
                      megadott adatok felhasználásával kizárólag ő vesz igénybe
                      szolgáltatást. E felelősségvállalásra tekintettel egy
                      megadott e-mail címen és/vagy adatokkal történt
                      belépésekkel összefüggő mindennemű felelősség kizárólag
                      azt a Felhasználót terheli, aki az e-mail címet
                      regisztrálta és az adatokat megadta.
                    </p>
                    <p className="text-justify">
                      Adatkezelő a megadott személyes adatokat nem ellenőrzi. A
                      megadott Személyes adatok helytállóságáért kizárólag az
                      azt megadó személy felel. A 18. életévét be nem töltött
                      személy érintett személyes adatai csak a felette szülői
                      felügyeletet gyakorló nagykorú személy hozzájárulása
                      esetén kezelhetők. Az Adatkezelőnek nem áll módjában a
                      hozzájáruló személy jogosultságát, illetve nyilatkozatának
                      tartalmát ellenőrizni, így a Felhasználó, illetve a
                      felette szülői felügyeletet gyakorló személy szavatol
                      azért, hogy a hozzájárulás megfelel a jogszabályoknak.
                      Hozzájáruló nyilatkozat hiányában az Adatkezelő 18.
                      életévét be nem töltött érintettre vonatkozó személyes
                      adatot – a tartalomszolgáltatás igénybevételekor használt
                      IP cím, böngésző adatok, látogatási paraméterek
                      kivételével, amelynek rögzítésére automatikusan sor kerül
                      – nem gyűjt.
                    </p>
                  </li>
                </ol>
                <h2>
                  Az Adatkezelő által megbízott adatfeldolgozókkal kapcsolatos
                  szabályok
                </h2>
                <ol>
                  <li>
                    <p className="text-justify">
                      Az Adatkezelő az általa kezelt személyes adatokkal
                      összefüggésben külső adatfeldolgozót vesz igénybe a
                      következő feladatok ellátása céljából:
                    </p>
                    <ul>
                      <li>
                        <p className="text-justify">
                          adó- és számviteli kötelezettségek teljesítése,
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          internetes honlap üzemeltetése, karbantartása,
                        </p>
                      </li>
                      <li>
                        <p className="text-justify">
                          marketingtevékenységgel, hírlevélküldéssel összefüggő
                          tevékenység
                        </p>
                      </li>
                    </ul>
                    <p className="text-justify">
                      Az adatfeldolgozók felsorolását jelen szabályzat 1. számú
                      melléklete tartalmazza.
                    </p>
                    <p className="text-justify">
                      Az adatfeldolgozók tevékenységük során az adatkezelésre
                      vonatkozó érdemi döntés meghozatalára kompetenciával nem
                      rendelkeznek, saját céljukra adatfeldolgozást nem
                      végezhetnek.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      <b>Adatbiztonságra vonatkozó rendelkezések</b>
                    </p>
                    <p className="text-justify">
                      <b>Az adatbiztonság megvalósításának elvei</b>
                    </p>
                    <p className="text-justify">
                      Az Adatkezelő személyes adatot csak a jelen szabályzatban
                      rögzített tevékenységekkel összhangban, az adatkezelés
                      célja szerint kezelhet.
                    </p>
                    <p className="text-justify">
                      Az Adatkezelő az adatok biztonságáról gondoskodik, e
                      körben kötelezettséget vállal arra, hogy megteszi mindazon
                      technikai és szervezési intézkedéseket, amelyek
                      elengedhetetlenül szükségesek az adatbiztonságra vonatkozó
                      jogszabályok, adat- és titokvédelmi szabályok érvényre
                      juttatásához, illetőleg kialakítja a fentiekben
                      meghatározott jogszabályok érvényesüléséhez szükséges
                      eljárási szabályokat.
                    </p>
                    <p className="text-justify">
                      Az Adatkezelő az adatokat megfelelő intézkedésekkel védi a
                      jogosulatlan hozzáférés, megváltoztatás, továbbítás,
                      nyilvánosságra hozatal, törlés vagy megsemmisítés,
                      valamint a véletlen megsemmisülés és sérülés, továbbá az
                      alkalmazott technika megváltozásából fakadó
                      hozzáférhetetlenné válás ellen.
                    </p>
                    <p className="text-justify">
                      Az Adatkezelő az általa kezelt adatokat az irányadó
                      jogszabályoknak megfelelően tartja nyilván, biztosítva,
                      hogy az adatokat csak azok a munkavállalók, és egyéb az
                      Adatkezelő érdekkörében eljáró személyek ismerhessék meg,
                      akiknek erre munkakörük, feladatuk ellátása érdekében
                      szükségük van.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      <b>
                        Az Adatkezelő informatikai nyilvántartásainak védelme
                      </b>
                    </p>
                    <p className="text-justify">
                      Az Adatkezelő az informatikai nyilvántartásai tekintetében
                      az adatbiztonság megvalósulásához a következő szükséges
                      intézkedéseket foganatosítja:
                    </p>
                    <p className="text-justify">
                      Ellátja az általa kezelt adatállományokat számítógépes
                      vírusok elleni állandó védelemmel (valós idejű
                      vírusvédelmi szoftvert alkalmaz).
                    </p>
                    <p className="text-justify">
                      Gondoskodik az informatikai rendszer hardver eszközeinek
                      fizikai védelméről, beleértve az elemi károk elleni
                      védelmet.
                    </p>
                    <p className="text-justify">
                      Gondoskodik az informatikai rendszer jogosulatlan
                      hozzáférés elleni védelméről, mind a szoftver, mind a
                      hardver eszközök tekintetében.
                    </p>
                    <p className="text-justify">
                      Megteszi mindazokat az intézkedéseket, amelyek az
                      adatállományok helyreállításához szükségesek, rendszeres
                      biztonsági mentést hajt végre, továbbá a biztonsági
                      másolatok elkülönített, biztonságos kezelését végrehajtja.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      <b>Az Adatkezelő papíralapú nyilvántartásainak védelme</b>
                    </p>
                    <p className="text-justify">
                      Az Adatkezelő a papíralapú nyilvántartások védelme
                      érdekében megteszi a szükséges intézkedéseket különösen a
                      fizikai biztonság, illetve tűzvédelem tekintetében.
                    </p>
                    <p className="text-justify">
                      Az Adatkezelő vezetője, munkavállalói, és egyéb, az
                      Adatkezelő érdekében eljáró személyek az általuk használt,
                      személyes adatokat is tartalmazó adathordozókat –
                      függetlenül az adatok rögzítésének módjától – kötelesek
                      biztonságosan őrizni és védeni a jogosulatlan hozzáférés,
                      megváltoztatás, továbbítás, nyilvánosságra hozatal, törlés
                      vagy megsemmisítés, valamint a véletlen megsemmisülés és
                      sérülés ellen.
                    </p>
                  </li>
                </ol>
                <h2>Egyéb rendelkezések</h2>
                <ol>
                  <li>
                    <p className="text-justify">
                      Az Adatkezelő bizonyos esetekben – hivatalos bírósági,
                      rendőrségi megkeresés, jogi eljárás, szerzői-, vagyoni-
                      illetve egyéb jogsértés vagy ezek alapos gyanúja miatt –
                      harmadik személyek számára hozzáférhetővé teszi az
                      érintett Felhasználó elérhető személyes adatait. Az
                      Adatkezelő jogosult és köteles minden olyan rendelkezésére
                      álló és általa szabályszerűen tárolt személyes adatot az
                      illetékes hatóságoknak továbbítani, amely személyes adat
                      továbbítására őt jogszabály vagy jogerős hatósági
                      kötelezés kötelezi. Ilyen adattovábbítás, valamint az
                      ebből származó következmények miatt az Adatkezelő nem
                      tehető felelőssé.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      A személyes adatokat az Adatkezelő az érintett Felhasználó
                      hozzájárulása alapján, és kizárólag célhoz kötötten
                      használja fel. Az Adatkezelő a személyes adatokat csak a
                      jelen Tájékoztatóban, illetve a vonatkozó jogszabályokban
                      meghatározott célból kezeli. A kezelt személyes adatok
                      köre arányban áll az adatkezelés céljával, azon nem
                      terjeszkedhet túl. Minden olyan esetben, ha a személyes
                      adatokat az Adatkezelő az eredeti adatfelvétel céljától
                      eltérő célra kívánja felhasználni, erről a Felhasználót
                      tájékoztatja, és ehhez előzetes, kifejezett hozzájárulását
                      megszerzi, illetőleg lehetőséget biztosít számára, hogy a
                      felhasználást megtiltsa.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      Az Adatkezelő fenntartja magának a jogot, hogy a jelen
                      Tájékoztatót egyoldalú döntésével bármikor módosítsa.
                    </p>
                  </li>
                  <li>
                    <p className="text-justify">
                      A Felhasználó a következő belépéssel elfogadja a
                      Tájékoztató mindenkor hatályos rendelkezéseit, ezen
                      túlmenően az egyes Felhasználó beleegyezésének kikérésére
                      nincs szükség.
                    </p>
                  </li>
                </ol>
                <p className="text-justify">
                  A jelen szabályzat elfogadása és módosítása az Adatkezelő
                  ügyvezetésének hatáskörébe tartozik.
                </p>
                <p className="text-justify">Budapest, 2021.04.15.</p>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};
export default Services;
